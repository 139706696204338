import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// Bus to Share info between Vue instances
const bus = new Vue();

// Search shown on Header Content
window.catalogSearch = new Vue({
    el: '#header-search',
    created: function () {
        bus.$on('updateListLength', length => this.listLength = length);
    },
    data: function () {
        return {
            searchKey: '',
            listLength: 0
        }
    },
    computed: {
        textSearch: function () {
            return this.listLength == 1 ? `1 resultado` : `${this.listLength} resultados`;
        }
    },
    methods: {
        openFilterModal: function () {
            bus.$emit('openFilterModal');
        },
    }
})

const router = new VueRouter({
    mode: 'history'
})

const catalogMain = new Vue({
    el: '#catalog-main',
    router,
    created() {
        // Set country_code
        getGeoInfo().then(geoinfo => this.country_code = geoinfo.user.country);

        //  if (productId) {
        //          this.selectedItem = this.items.find(item => item.productid == productId);
        //          this.isOpenModalProduct = true;
        if (productId) {

            // Find product by id
            if (productId) {
                let product = this.items.find(item => item.productid == productId);
                if (product) {
                    // Open Modal
                    this.selectedItem = product;
                    this.isOpenModalProduct = true;
                }
            }
        }

        catalogSearch.$on('filterBySearchBox', this.updateSearchKey);

        if (screen.width < 700) {
            this.isOpenSorter = false;
            this.isOpenFilters = false;
        }

        this.options.min = Math.floor(this.items.reduce((prev, curr) => parseFloat(prev.final_price) < parseFloat(curr.final_price) ? prev : curr).final_price);
        this.options.max = Math.ceil(this.items.reduce((prev, curr) => parseFloat(prev.final_price) > parseFloat(curr.final_price) ? prev : curr).final_price + 1);
        this.value[0] = this.options.min;
        this.value[1] = this.options.max;
        Vue.set(this.value, '0', this.options.min)
        Vue.set(this.value, '1', this.options.max);

        // filter Modal
        bus.$on('openFilterModal', () => this.showFilterModal = true);
    },
    data() {
        return {
            items: products,
            parPage: 12,
            currentPage: 1,
            searchKey: '',

            value: [0, 100],
            options: {
                min: 0,
                max: 100,
            },
            country_code: '',

            // Sections on Filters :
            isOpenSorter: true,
            selectedSorter: 'Relevancia',
            isOpenFilters: true,

            // Modal Product
            selectedItem: {},
            isOpenModalProduct: false,

            // Filter as Modal
            showFilterModal: false
        }
    },
    methods: {
        clickCallback: function (pageNum) {
            this.currentPage = Number(pageNum);
        },
        updateSearchKey(data) {
            this.searchKey = data;
        },
        onClickProduct: function (item) {
            this.selectedItem = item;
            this.isOpenModalProduct = true;
            // Change vue router
            this.$router.push({path: '/code/' + item.productid})
        },
        hideModalProduct: function (item) {
            this.$router.push({path: '/'})
        },
        getFormattedPrice: function (currency, price) {
            if (this.country_code && currency && price) {
                return new Intl.NumberFormat(`${appLocale}-` + this.country_code, {
                    style: 'currency',
                    currency: currency
                }).format(price);
            }
            return `${currency} ${price}`;
        }
    },
    computed: {
        filteredList() {
            // Filter
            let list = this.items.filter((item) => {
                return this.searchKey.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v)) &&
                    parseFloat(item.final_price) >= this.value[0] &&
                    parseFloat(item.final_price) <= this.value[1];
            });
            // Sorter
            if (this.selectedSorter === 'Menor Precio') {
                list.sort(function (a, b) {
                    return parseFloat(a.final_price) - parseFloat(b.final_price);
                });
            }
            if (this.selectedSorter === 'Mayor Precio') {
                list.sort(function (a, b) {
                    return parseFloat(b.final_price) - parseFloat(a.final_price);
                });
            }
            return list
        },
        getItems: function () {
            let current = this.currentPage * this.parPage;
            let start = current - this.parPage;
            return this.filteredList.slice(start, current);
        },
        getPageCount: function () {
            return Math.ceil(this.filteredList.length / this.parPage);
        },
        classFilterModal: function () {
            return {
                'filter-modal--show': this.showFilterModal,
            }
        }
    },
    watch: {
        filteredList(newVal) {
            bus.$emit('updateListLength', newVal.length);
        }
    }
})
